import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
  fetchUtils,
} from "react-admin";

import { stringify } from "query-string";

import { cloudinaryUpload } from "utils/cloudinary";
import { getResourceRoute } from "utils/routing";

const CLOUDINARY_UPLOAD_FIELDS = [
  "picture_1",
  "picture_2",
  "picture_3",
  "picture_4",
  "picture_5",
  "selling_picture_1",
  "selling_picture_2",
  "selling_picture_3",
  "selling_picture_4",
  "selling_picture_5",
];

const UNPAGINATED_RESOURCES = [
  "account",
  "aswo-article",
  "billing-elements",
  "billing-quotes",
  "collection-files",
  "comments",
  "comment-attachments",
  "contractors",
  "credit-notes-to-settle",
  "customer-file-states-unpaginated",
  "deliveries",
  "delivery-options",
  "demand-item",
  "employees",
  "email-actions",
  "fault-forecast-report",
  "installation-types",
  "location",
  "package",
  "package-types",
  "paymentbilling-history",
  "products",
  "product-brands",
  "product-models",
  "package-price",
  "product-types",
  "product-type-attributes",
  "refunds",
  "repair-report-checks",
  "send-temporisation-email",
  "shipping-providers",
  "shipping/deliveries",
  "shipping-timeslots",
  "skus",
  "sku-demand",
  "sku-location",
  "sku-log",
  "sku-model",
  "sku-model-references",
  "sku-price-history",
  "sku-selling-price",
  "sku-suppliers",
  "sku-supplying",
  "deprecated-spare-parts",
  "ticket-tags",
  "ticket-states",
  "visits",
  "workshop",
  "workshop-file-tags",
  "workshop-files-count",
  "cancellation-fees",
  "warranty",
  "timeslot",
  "repair-package-prices",
  "visit-preparations",
  "zone",
];

const FILE_RESOURCES = ["delivery-notes", "comment-attachments"];

type HeadersType = {
  get: (arg0: string) => string;
  has: (arg0: string) => boolean;
};
type ParamsType = {
  data: { [key: string]: any };
  filter?: {
    page?: number;
    page_size?: number;
  };
  id: number;
  ids?: number[];
  noPagination?: boolean;
  pagination?: {
    page: number;
    perPage: number;
  };
  postal_code?: string;
  product_type?: string;
  previousData?: string;
  sort?: {
    field: string;
    order: string;
  };
  target?: string;
  timeslots_ids?: number[];
  uuid?: string;
};
type RawResponseType = {
  headers: HeadersType;
  json: {
    count: number;
    detail: string;
    length: number;
    results: Object[];
  };
  status: number;
  body: Object;
};
type UpdateResponseType = { data: Object; status: number };
type ErrorType = { body: Object; status: number };

/**
 * Maps react-admin queries to the default format of Django REST Framework
 */
function drfProvider(apiUrl: string, httpClient = fetchUtils.fetchJson) {
  /**
   * @param {String} type React-admin request type, e.g. 'GET_LIST'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params Request parameters. Depends on the request type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHttp = (type: string, resourceAPIRoute: string, params: ParamsType, resource: string) => {
    let url = "";
    const options: { body?: Object; method?: string } = {};

    switch (type) {
      case CREATE:
        url = `${apiUrl}/${resourceAPIRoute}/`;
        options.method = "POST";
        if (FILE_RESOURCES.includes(resource)) {
          const formData = new FormData();
          Object.entries(params.data).forEach((value) => formData.append(...value));
          options.body = formData;
        } else {
          options.body = JSON.stringify(params.data);
        }
        break;
      case GET_ONE:
        if (resourceAPIRoute === "nameplates") {
          url = `${apiUrl}/murfy-erp/products/${params.id}/nameplate/`;
        } else if (params.postal_code) {
          url = `${apiUrl}/${resourceAPIRoute}/?postal_code=${params.postal_code}`;
          if (params.product_type) {
            url += `&product_type=${params.product_type}`;
          }
        } else if (params.id === undefined) {
          url = `${apiUrl}/${resourceAPIRoute}/`;
        } else {
          url = `${apiUrl}/${resourceAPIRoute}/${params.id}/`;
        }
        break;
      case GET_LIST: {
        const { field, order } = params.sort || {};
        const { filter } = params;
        const query = {
          ordering: order && `${order === "ASC" ? "" : "-"}${field}`,
          ...filter,
        };
        if (params.pagination) {
          query.page = params.pagination.page;
          query.page_size = params.pagination.perPage;
        }
        if (resourceAPIRoute === "shipping/delivery-options" && params.postal_code) {
          url = `${apiUrl}/${resourceAPIRoute}/${params.postal_code}/?${stringify(query)}`;
        } else if (resource === "cancellation-fees" && params.uuid) {
          url = `${apiUrl}/${resourceAPIRoute}/${params.uuid}/cancellation-fees/`;
        } else if (resource === "credit-notes-to-settle" && params.id) {
          url = `${apiUrl}/ecom/order/${params.id}/credit-notes-to-settle/`;
        } else {
          url = `${apiUrl}/${resourceAPIRoute}/?${stringify(query)}`;
        }
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination || {};
        const { field, order } = params.sort || {};
        const { filter, target, id } = params;
        const query: { [key: string]: any } = {
          page,
          page_size: perPage,
          ordering: `${order === "ASC" ? "" : "-"}${field}`,
          ...filter,
        };
        if (target) {
          query[target] = id;
        }
        url = `${apiUrl}/${resourceAPIRoute}/?${stringify(query)}`;
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resourceAPIRoute}/${params.id}/`;
        options.method = "PATCH";
        if (params.data.identification_plate) {
          const formData = new FormData();
          Object.entries(params.data).forEach((value) => formData.append(...value));
          options.body = formData;
        } else {
          options.body = JSON.stringify(params.data);
        }
        break;
      case DELETE:
        url = `${apiUrl}/${resourceAPIRoute}/${params.id}/`;
        options.method = "DELETE";
        break;
      default:
        throw new Error(`Unsupported Data Provider request type ${type}`);
    }

    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type React-admin request type, e.g. 'GET_LIST'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params Request parameters. Depends on the request type
   * @returns {Object} Data response
   */
  const convertHttpResponse = (response: RawResponseType, type: string, resource: string, params: ParamsType) => {
    const { headers, json, status, body } = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY:
      case GET_MANY_REFERENCE:
        if (Number.isInteger(json)) {
          return { data: json, total: 1 };
        } else if ("count" in json) {
          return { data: json.results, total: json.count };
        } else if (headers.has("content-range")) {
          return {
            data: json,
            total: parseInt(headers.get("content-range").split("/").pop() || "0", 10),
          };
        } else if ("detail" in json && json.detail === "Invalid page.") {
          return { data: [], total: 0 };
        } else if (params.noPagination || UNPAGINATED_RESOURCES.includes(resource)) {
          return { data: json, total: json.count || json.length };
          /**
           * for ticket tags and states the data fetched by the data provider is an array
           *  and not an object with attributes data and count
           */
        } else {
          throw new Error(
            "The total number of results is unknown. The DRF data provider " +
              "expects responses for lists of resources to contain this " +
              "information to build the pagination. If you're not using the " +
              "default PageNumberPagination class, please include this " +
              'information using the Content-Range header OR a "count" key ' +
              "inside the response."
          );
        }
      case CREATE:
        return {
          data: json || body,
          status,
          // I don't have access to response headers here and I don't know why, does anyone know ?
          headers: resource === "murfy-erp/spare-parts/bulk" ? { ContentType: "text/csv" } : headers,
        };
      case DELETE:
        return { data: params.previousData };
      case UPDATE:
        return { data: json, status };
      default:
        return { data: json };
    }
  };

  const convertHttpError = ({ body, status }: ErrorType) => ({ data: body, status });

  /**
   * @param {String} type React-admin request type, e.g. 'GET_LIST'
   * @param {string} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type: string, resource: string, params: ParamsType) => {
    /**
     * Map resource name to API endpoint.
     * By defaut, the resource name is used as the endpoint if not specified otherwise.
     */
    const resourceAPIRoute = getResourceRoute(resource);
    const route = `${apiUrl}/${resourceAPIRoute}`;

    /**
     * Split GET_MANY, UPDATE_MANY and DELETE_MANY requests into multiple promises,
     * since they're not supported by default.
     */
    switch (type) {
      case GET_MANY:
        if (params.timeslots_ids) {
          return Promise.all(
            params.timeslots_ids.map((id) => httpClient(`${route}/?timeslot_id=${id}`, { method: "GET" }))
          ).then((responses) => ({ data: responses.map((response) => response.json) }));
        } else if (params.ids) {
          return Promise.all(params.ids.map((id) => httpClient(`${route}/${id}/`, { method: "GET" }))).then(
            (responses) => ({ data: responses.map((response) => response.json) })
          );
        } else {
          throw Error("Missing GET_MANY object ids");
        }
      case UPDATE_MANY:
        if (!params.ids) {
          throw Error("Missing UPDATE_MANY object ids");
        }
        return Promise.all(
          params.ids.map((id) => httpClient(`${route}/${id}/`, { method: "PATCH", body: JSON.stringify(params.data) }))
        ).then((responses) => ({ data: responses.map((response) => response.json) }));
      case DELETE_MANY:
        if (!params.ids) {
          throw Error("Missing DELETE_MANY object ids");
        }
        return Promise.all(params.ids.map((id) => httpClient(`${route}/${id}`, { method: "DELETE" }))).then(
          (responses) => ({
            data: responses.map((response) => response.json),
          })
        );
      default:
        break;
    }

    const { url, options } = convertDataRequestToHttp(type, resourceAPIRoute, params, resource);
    return httpClient(url, options)
      .then((response: RawResponseType) => convertHttpResponse(response, type, resource, params))
      .catch((error: ErrorType) => convertHttpError(error));
  };
}

const httpClient = (url: string, options: { headers?: Headers } = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Token ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = drfProvider(process.env.REACT_APP_API_URL || "", httpClient);

export default {
  create: (resource: string, params: ParamsType) => dataProvider(CREATE, resource, params),
  delete: (resource: string, params: ParamsType) => dataProvider(DELETE, resource, params),
  deleteMany: (resource: string, params: ParamsType) => dataProvider(DELETE_MANY, resource, params),
  getList: (resource: string, params: ParamsType) => dataProvider(GET_LIST, resource, params),
  getMany: (resource: string, params: ParamsType) => dataProvider(GET_MANY, resource, params),
  getManyReference: (resource: string, params: ParamsType) => dataProvider(GET_MANY_REFERENCE, resource, params),
  getOne: (resource: string, params: ParamsType) => dataProvider(GET_ONE, resource, params),
  update: (resource: string, params: ParamsType): Promise<UpdateResponseType> => {
    // Fallback to the default implementation
    if (resource !== "products" && resource !== "product-models") {
      return dataProvider(UPDATE, resource, params);
    }

    // For products update only, upload images to Cloudinary and store the URL in the API
    const uploadPromises: Promise<{ key: any; url: any }>[] = [];
    CLOUDINARY_UPLOAD_FIELDS.forEach((key) => {
      if (params.data[key] !== undefined) {
        uploadPromises.push(cloudinaryUpload(key, params.data[key]));
      }
    });

    return Promise.all(uploadPromises)
      .then((uploadedPictures) =>
        uploadedPictures.reduce((acc, current) => ({ ...acc, [current.key]: current.url }), {})
      )
      .then((uploadedPictures) =>
        dataProvider(UPDATE, resource, {
          ...params,
          data: {
            ...params.data,
            ...uploadedPictures,
          },
        })
      );
  },
  updateMany: (resource: string, params: ParamsType) => dataProvider(UPDATE_MANY, resource, params),
};
