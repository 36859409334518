import React, { useState } from "react";

import { SimpleForm, useCreate, useRefresh } from "react-admin";

import { Typography, makeStyles } from "@material-ui/core";

import { toastr } from "react-redux-toastr";

import AdminInputs from "components/AdminInputs";
import CalendarInput from "components/CalendarInput";
import ClientInformationInputs from "components/ClientInformationInputs/ClientInformationInputs";
import ContractorInformationInputs from "components/ContractorInformationInputs";
import { SimpleValidateToolbar } from "components/FormToolbars";
import ProductDescriptionInputs from "components/ProductDescriptionInputs";

import AvailableTimeSlot from "./AvailableTimeSlot";

const products = require(`${process.env.REACT_APP_PRODUCT_CONF_FILE}.json`);

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: "white",
    position: "absolute",
    right: 24,
    bottom: 24,
    padding: 0,
    margin: 0,
  },
}));

type CustomerFileCreateFormProps = { onSuccess: (fileNumber: string) => void };
type FormValues = {
  category: string;
  firstname: number;
  lastname: string;
  phone: string;
  email: string;
  symptom: string;
  address: string;
  street_number: string;
};

const CustomerFileCreateForm = (props: CustomerFileCreateFormProps) => {
  const [isClean, setIsClean] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();
  const [create] = useCreate("customer-files");
  const refresh = useRefresh();

  const createCustomerFile = (values: FormValues) => {
    setIsSubmitting(true);
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data, status }: any) => {
          if (status < 200 || status >= 300) {
            toastr.error("Erreur", `Une erreur est survenue lors de la création du dossier ${JSON.stringify(data)}`);
          } else {
            toastr.success("Succès", "Le dossier client a bien été créé");
            props.onSuccess(data.file_number);
            refresh();
          }
        },
        onFailure: ({ error }: any) => {
          toastr.error("Erreur", error.message);
        },
      }
    );
    setIsSubmitting(false);
  };

  const validateFileCreation = (values: FormValues) => {
    const errors: any = {};
    if (!values.firstname) {
      errors.firstname = ["Prénom du client manquant"];
    }
    if (!values.lastname) {
      errors.lastname = ["Nom du client manquant"];
    }
    if (
      (values.phone && values.phone.replace(/ /g, "").includes("+33") && values.phone.replace(/ /g, "").length < 12) ||
      values.phone === "+" ||
      !values.phone
    ) {
      errors.phone = ["Numéro de téléphone du client manquant"];
    }
    if (!values.email) {
      errors.email = ["email du client manquant"];
    }
    if (values.symptom && values.symptom.length > 64) {
      errors.symptom = ["maximum 64 caractères"];
    }
    if (values.address && !values.street_number) {
      errors.address = ["numéro de rue manquant"];
    }
    if (Object.keys(errors).length === 0) {
      setIsClean(true);
    }
    return errors;
  };

  const getInitialValues = () => {
    const url = window.location.href;
    const params = new URLSearchParams(new URL(url).search);

    return {
      category: "home_repair",
      source: params.get("source"),
      address: params.get("address"),
      street_number: params.get("street_number"),
      street: params.get("street"),
      city: params.get("city"),
      postal_code: params.get("postal_code"),
      firstname: params.get("firstname"),
      lastname: params.get("lastname"),
      phone: params.get("phone"),
      email: params.get("email"),
      product_type: params.get("product_type"),
      brand: params.get("brand"),
    };
  };

  return (
    <SimpleForm
      validate={validateFileCreation}
      submitOnEnter={false}
      redirect={false}
      initialValues={getInitialValues()}
      toolbar={
        <SimpleValidateToolbar
          disabled={!isClean || isSubmitting}
          onSave={createCustomerFile}
          classes={classes}
          label="créer le dossier"
          version="right"
          size="large"
        />
      }
    >
      <>
        <Typography variant="h2">Infos Client</Typography>
        <ClientInformationInputs />
      </>
      <>
        <Typography variant="h2">Donneur d'ordre</Typography>
        <ContractorInformationInputs />
      </>
      <>
        <Typography variant="h2">Créneau</Typography>

        <AvailableTimeSlot>
          <CalendarInput source="timeslot" postalCodeFieldName="postal_code" />
        </AvailableTimeSlot>
      </>
      <>
        <Typography variant="h2">Produit et Panne</Typography>
        <ProductDescriptionInputs isProductCreation productsNomenclature={products} showAutoComplete={false} />
      </>
      <>
        <Typography variant="h2">Infos Admin</Typography>
        <AdminInputs />
      </>
    </SimpleForm>
  );
};

export default CustomerFileCreateForm;
